import React, {useState, useEffect, useRef} from 'react';
import HeaderRoutes from '../../components/HeaderRoutes/HeaderRoutes';
import { Box, Grid, Paper, Typography , TextField, CircularProgress, InputAdornment } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import config from '../../config/config';
import CancelIcon from "@mui/icons-material/Cancel";
import UseFormControl from '../../components/SearchBox/SearchBox';
import UpdateModal from './UpdateModal';
import Toast from '../../config/Toast';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const UpdateVariables = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [rows, setRows] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debounceRef = useRef();
  const cache = useRef({});
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [modalData, setModalData] = useState({
    department: '',
    variableName: '',
    currentValue: '',
    newValue: '',
  });
  const [showOnlyDifferent, setShowOnlyDifferent] = useState(false);


  const handleChange = (event) => {
    const value = event.target.value;
    if (value.length <= 3) {
      setSelectedDepartments(value);
    }
    if (value.length >= 3) {
      setIsOpen(false);
    }
  };

  const handleDelete = (valueToRemove) => {
    setSelectedDepartments((prev) =>
      prev.filter((value) => value !== valueToRemove)
    );
  };

  const baseColumns = [
    {
      field: '',
      headerName: '',
      width: 70,
      renderCell: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box component="img" src="/images/blue-icon.svg" alt="icon" />
        </Box>
      ),
    },
    {
      field: 'variable_name',
      headerName: 'Variable name',
      width: 300,
    },
    ...selectedDepartments.map((dept) => ({
      field: dept,
      headerName: dept,
      width: 180,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box
            component="img"
            src="/images/edit-icon.svg"
            alt="department-icon"
            sx={{ width: 18, height: 18, cursor: 'pointer' }}
            onClick={() => handleIconClick(params)}
          />
          <span>{params.value}</span> 
        </Box>
      ),
    })),
  ];
  
  const handleIconClick = (params) => {
    setModalData({
      department: params.colDef.headerName,
      variableName: params.row.variable_name,
      currentValue: params.value,
      newValue: '',
    });
    setOpenModal(true);
  };

  const handleUpdate = (updatedData) => {
    setModalData(updatedData);
  };

  const fetchListData = async () => {
    const dataLive = { type: "LIVE" };
    const dataTest = { type: "TEST" };
    const path = `${config.authServiceUrl}database/list`;
    const token = localStorage.getItem("tokenId");
  
    try {
      const responseLive = await axios.post(path, dataLive, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      const responseTest = await axios.post(path, dataTest, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      const idsLive = responseLive.data.data.map((item) => ({
        id: item.id,
        department_name: item.department_name,
      }));
  
      const idsTest = responseTest.data.data.map((item) => ({
        id: item.id,
        department_name: item.department_name,
      }));
  
      const combinedIds = Array.from(new Map(
        [...idsLive, ...idsTest].map((item) => [item.id, item])
      ).values());
  
      setDepartment(combinedIds);
    } catch (error) {
      console.error("Error:", error.response?.data?.message || "Failed to fetch departments");
    }
  };

  const fetchData = async (page = 1) => {
    const cacheKey = JSON.stringify({
      searchTerm,
      selectedDepartments: [...selectedDepartments].sort(),
      page,
    });
  
    if (cache.current[cacheKey]) {
      const cachedRows = cache.current[cacheKey];
      setRows(filterDifferentRows(cachedRows));
      return;
    }
  
    const data = {
      departments: selectedDepartments,
      search: searchTerm,
      page,
      per_page: 100,
    };
  
    const path = `${config.authServiceUrl}database/config/variables-view`;
    const token = localStorage.getItem("tokenId");
  
    try {
      const response = await axios.post(path, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      const transformedRows = Object.entries(response.data.data).map(([key, value]) => {
        const departments = selectedDepartments.reduce((acc, deptKey) => {
          acc[deptKey] = value[deptKey] || '';
          return acc;
        }, {});
  
        return {
          id: key,
          variable_name: key,
          ...departments,
        };
      });
  
      cache.current[cacheKey] = transformedRows;
      const filteredRows = filterDifferentRows(transformedRows);
  
      setRows((prevRows) => {
        const hasChanges = JSON.stringify(prevRows) !== JSON.stringify(filteredRows);
        return hasChanges ? filteredRows : prevRows;
      });
    } catch (error) {
      console.error('Error:', error.response?.data?.message || 'Failed to fetch data');
    }
  };
  
  const filterDifferentRows = (rows) => {
    if (!showOnlyDifferent) return rows;
  
    return rows.filter((row) => {
      const values = selectedDepartments.map((dept) => row[dept] || '');
      return new Set(values).size > 1; 
    });
  };

  
  const handlePagination = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  useEffect(()=>{
    fetchListData()
    fetchData(currentPage);
  }, [selectedDepartments, searchTerm , showOnlyDifferent])
  
  const handleSearch = (term) => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      setSearchTerm(term); 
    }, 200); 
  };

  const updateData = async () => {
    const data = {
      department: modalData.department,
      variable: modalData.variableName,
      new_value: modalData.newValue,
    };
  
    const path = `${config.authServiceUrl}database/config/edit-variable`;
    const token = localStorage.getItem('tokenId');
  
    try {
      const response = await axios.post(path, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      setMessage(response.data.data);
      setType('success');
      setSnackbarOpen(true);
  
      const updatedRows = rows.map((row) =>
        row.variable_name === modalData.variableName
          ? { ...row, [modalData.department]: modalData.newValue }
          : row
      );
  
      const cacheKey = JSON.stringify({
        searchTerm,
        selectedDepartments: [...selectedDepartments].sort(),
        page: 1, 
      });
  
      if (cache.current[cacheKey]) {
        cache.current[cacheKey] = updatedRows;
      }
      
      setTimeout(() => {
        setRows(updatedRows); 
      }, 1000);
      setLoading(false);
      
    } catch (error) {
      console.log('Error:', error.response?.data?.message);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const filteredDepartments = department.filter(
    (item) =>
      item.department_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.id.toString().includes(searchQuery)
  );

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value); 
  };


  return (
    <>
      <HeaderRoutes />
      <Paper className="m-4 px-4 pt-4" style={{ overflowX: 'auto', maxWidth: isMobile ? '86vw' : '' }}>
        <div>
          <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                Update Variables
              </Typography>

              <Box sx={{ my: 3 }}>
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        sx={{
                          width: "100%",
                          height: 52,
                          "& .MuiInputBase-root": {
                            height: "100%",
                          },
                        }}
                      >
                        <InputLabel id="demo-multiple-chip-label">
                          Please Select the Department
                        </InputLabel>
                        <Select
                          multiple
                          value={selectedDepartments}
                          onChange={handleChange}
                          open={isOpen}
                          onOpen={() => setIsOpen(true)}
                          onClose={() => setIsOpen(false)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Please Select the Department"
                            />
                          }
                          renderValue={(selected) => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  onDelete={() => handleDelete(value)}
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event) => event.stopPropagation()}
                                    />
                                  }
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: { maxHeight: 400, overflow: "auto" },
                            },
                            disableAutoFocusItem: true,
                          }}
                        >
                          <MenuItem disableGutters>
                            <TextField
                              placeholder="Search Departments"
                              variant="outlined"
                              fullWidth
                              size="small"
                              value={searchQuery}
                              onChange={handleInputChange}
                              onClick={(event) => event.stopPropagation()}
                              onKeyDown={(e) => e.stopPropagation()}
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: "36px",
                                  padding: '0px 8px',
                                },
                                margin: '0 16px', 
                              }}
                            />
                          </MenuItem>

                          {filteredDepartments.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {`${item.id} - ${item.department_name}`}
                            </MenuItem>
                          ))}

                          {filteredDepartments.length === 0 && searchQuery && (
                            <MenuItem disabled>No results found</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                      <UseFormControl onSearch={handleSearch} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showOnlyDifferent}
                            onChange={(e) => setShowOnlyDifferent(e.target.checked)}
                          />
                        }
                        label="Only show different rows"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
    
              {selectedDepartments.length === 0 ? (
                <Box sx={{ my: 3 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ padding: isMobile ? '16px' : '22px', border: '1px solid #cccccc', borderRadius: '4px' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#2E3238' }}>
                          Please select department to view variables
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', height: isMobile ? '20vh' : '50vh' }}>
                          <img src='/images/select-variable.svg' alt='Select Variable' />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box sx={{ my: 3 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ border: '1px solid #cccccc', borderRadius: '4px' }}>
                        <Box sx={{ height: '60vh'}}>
                          <DataGrid
                            rows={rows}
                            columns={baseColumns}
                            sx={{ border: 0 }}
                            pagination
                            pageSize={100}
                            rowsPerPageOptions={[100]}
                            page={currentPage - 1}
                            onPageChange={(newPage) => handlePagination(newPage + 1)}
                          />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      </Paper>
      <UpdateModal
        open={openModal}
        onClose={handleClose}
        modalData={modalData}
        onUpdate={handleUpdate}
        onUpdateData={updateData} 
      />
      <Toast
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        message={message}
        type={type}
      />
    </>
  );
};

export default UpdateVariables;









